<template>
  <el-select v-model="id_leechero" placeholder="Seleccionar" 
    filterable    
    remote
    :remote-method="remoteMethod"
    class="w-100 mb-3" size="small">
    <el-option
    v-for="leechero in leecheros"
    :key="leechero.id"
    :label="label(leechero)"
    :value="leechero.id">
  </el-option>
</el-select>
</template>

<script>
import Util from '~/services/util'
import { mapGetters } from 'vuex'
export default {   
  data(){
    return{
      leecheros:[],      
      search:'',
      id_leechero:null
    }
  },
  mounted(){
    if(this.conjunto.base_datos){
      this.getLecheeros()
    }
  },
  computed:{
    ...mapGetters({
      conjunto: 'conjuntos/conjunto',
    }),
    idConjunto(){
      return this.$route.params.id
    }
  },

  watch:{
    conjunto:{
      handler(val){
        this.getLecheeros()
      },
      deep: true
    },
    id_leechero(val){
      this.$emit('setLeechero',val)
    }
  },
  methods:{    
    remoteMethod(search){
      this.search = search
      setTimeout(() => {
        this.loading = false;
        this.getLecheeros()
      }, 100);
    },
    async getLecheeros(){      
      try {
        const params = {
          search : this.search,
          id_conjunto : this.$route.params.id
        }
        const {data} = await Util.leecherosFiltro(params)
        this.leecheros = data.data	
      } catch (e){
        console.log(e)
      }
    },
    label(leechero){
      return leechero.prop_nombre+' '+leechero.prop_apellido+' ('+leechero.cedis_nombre+')'
    },
    addLeechero(item){
      if(this.leecheros.every(el => el.id != item.id)) this.leecheros.push(item)
    },  
    setIdLeechero(id_leechero){
      this.id_leechero = id_leechero
    }
  }
}
</script>

<style>

</style>
<template>
  <el-select v-model="id_pais" placeholder="Seleccionar" 
    filterable    
    remote
    :remote-method="remoteMethod"
    class="w-100 mb-3" size="small">
    <el-option
    v-for="pais in paises"
    :key="pais.id"
    :label="pais.pais"
    :value="pais.id">
  </el-option>
</el-select>
</template>

<script>
import Util from '~/services/util'
export default {
  data(){
    return{
      paises:[],
      
      id_pais:'',
      params : {
        search:''
      }
    }
  },
  created(){
    this.search()
  },
  watch:{
    id_pais(val){
      this.$emit('setPais',val)
    }
  },
  methods:{    
    remoteMethod(search){
      this.params.search = search
      setTimeout(() => {
        this.loading = false;
        this.search()
      }, 100);
    },
    async search(){
      
      try {
        const {data} = await Util.paisesFiltro(this.params)
        console.log(data.data)
        this.paises = data.data	
      } catch (e){
        console.log(e)
      }
    }
  }
}
</script>

<style>

</style>
import Conjuntos from '../../services/conjuntos'
import * as types from '../mutation-types'
// state
export const state = {
  conjuntos: [
    {
        nombre_conjunto: 'El Olympo Condominio',
        img: 'https://www.metrocuadrado.com/noticias/sites/default/files/field/image/istock-1069330600.jpg',
        tipo: 'Edificio',
        viviendas: 15,
        usuarios: 5,
        id: 1,
        ciudad: 'Bucaramanga'
    },
    {
        nombre_conjunto: 'La gran reserva condominios',
        img: 'https://www.metrocuadrado.com/noticias/sites/default/files/field/image/istock-1069330600.jpg',
        tipo: 'Edificio',
        viviendas: 15,
        usuarios: 5,
        id: 2,
        ciudad: 'Bucaramanga'
    },
    {
        nombre_conjunto: 'Provenza club El Condominio',
        img: 'https://www.metrocuadrado.com/noticias/sites/default/files/field/image/istock-1069330600.jpg',
        tipo: 'Edificio',
        viviendas: 15,
        usuarios: 5,
        id: 3,
        ciudad: 'Bucaramanga'
    }
  ],
  conjunto:{}
}

// getters
export const getters = {
  conjuntos: ({conjuntos}) => conjuntos,
  conjunto: ({conjunto}) => conjunto
}

// mutations
export const mutations = {
  [types.OBTENER_CONJUNTO] (state, { conjunto }) {
    state.conjunto = conjunto
  },
}


// actions
export const actions = {  
  quitarConjunto({ state }){
    state.conjunto = {}
  },
  async obtener ({ commit }, conjunto ) {
    const params = {
      id_conjunto : conjunto
    }
    const { data } = await Conjuntos.getConjunto(params)

    commit(types.OBTENER_CONJUNTO, { conjunto: data.data })
  }
}

<template>
    <div class="main-layout">
        <div 
        class="position-relative app-wrapper" 
        style="width:calc(100vw - 0px);">
            <div v-if="false" class="loading-login w-100 h-100 d-middle-center" style="position:absolute;z-index:10">
                <img src="/img/logos/logo_login.svg" class="obj-cover " style="max-width:100%;max-height:100%;" />
            </div>
            <div class="contenedor px-0">
                <child />
            </div>
        </div>
    </div>
</template>

<script>
import '../assets/sass/app.scss'
export default {
    name: 'LoginLayout',
}
</script>
<style lang="scss" scoped>
main{
    padding-left: 65px !important
}
.app-wrapper {
    font-family: 'Rubik';
    color: var(--text-general);
    height: 100vh;
    width:calc(100vw - 100px);
    background-image: url('/img/logos/background.png');
    background-position: 0% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: auto;
    // z-index: 1;
    display: flex;
    flex-flow: column;
    align-items: stretch;
    justify-content: stretch;

    & > .full-height-wrapper {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
        flex: 1 1 auto;

        .conf-section {
            flex: 1 1 auto;
            margin: 1.5rem 1rem;
            padding: 1rem;
        }

    }
}
.contenedor{
    height: 100%;
    width: 100%;
    margin: auto 0;
}
.loading-login{
    background-image: linear-gradient(to bottom, #717171, #000000);
}
</style>

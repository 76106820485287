export default [
    {
        path: '/conjuntos-estadisticas/:id',
        name: 'conjuntos.estadisticas',
        component: () => import(/* webpackChunkName: "configurar" */ '../../pages/conjuntos/estadisticas').then(m => m.default || m),
    },
    
    {
        path: '/conjuntos-estructura-residencial/:id',
        name: 'conjuntos.estructura-residencial',
        component: () => import(/* webpackChunkName: "configurar" */ '../../pages/conjuntos/estructuraResidencial').then(m => m.default || m),
                
    },
    {
        path: '/conjuntos-estructura-residencial/:id/crear-vivienda',
        name: 'conjuntos.estructura-residencial.crear-vivienda',
        component: () => import(/* webpackChunkName: "configurar" */ '../../pages/conjuntos/crearVivienda').then(m => m.default || m),
        meta: {
            breadcrumb: [
                {
                    title: 'Crear', name: 'conjuntos.estructura-residencial.crear-vivienda'
                },
            ]
        }
    },
    {
        path: '/conjuntos/administradores/:id',
        name: 'conjuntos.administradores',
        component: () => import(/* webpackChunkName: "configurar" */ '../../pages/conjuntos/administradores').then(m => m.default || m),
    },
    {
        path: '/conjuntos/administradores/:id/crear-administrador',
        name: 'conjuntos.administradores.crear-administrador',
        component: () => import(/* webpackChunkName: "configurar" */ '../../pages/conjuntos/crearAdministrador').then(m => m.default || m),
    },
    {
        path: '/conjuntos-leecheros-asignados/:id',
        name: 'conjuntos.leecheros-asignados',
        component: () => import(/* webpackChunkName: "configurar" */ '../../pages/conjuntos/leecherosAsignados').then(m => m.default || m),
    },
    {
        path: '/conjuntos-leecheros-asignados/:id/asignar-leechero',
        name: 'conjuntos.leecheros-asignados.asignar-leechero',
        component: () => import(/* webpackChunkName: "configurar" */ '../../pages/conjuntos/asignarLeechero').then(m => m.default || m),
    },
    {
      path: '/conjuntos-leecheros-asignados/:id/asignar-leechero/:id_leechero',
      name: 'conjuntos.leecheros-asignados.asignar-leechero-id',
        component: () => import(/* webpackChunkName: "configurar" */ '../../pages/conjuntos/asignarLeechero').then(m => m.default || m),
    },
    {
        path: '/conjuntos/vigilantes/:id',
        name: 'conjuntos.vigilantes',
        component: () => import(/* webpackChunkName: "configurar" */ '../../pages/conjuntos/vigilantes').then(m => m.default || m),
    },
    {
        path: '/conjuntos/vigilantes/:id/crear',
        name: 'conjuntos.crear.vigilante',
        component: () => import(/* webpackChunkName: "configurar" */ '../../pages/conjuntos/crearVigilante').then(m => m.default || m),
    },
    {
        path: '/conjuntos/vigilantes/:id/editar/:idVigilante',
        name: 'conjuntos.crear.vigilante-id',
        component: () => import(/* webpackChunkName: "configurar" */ '../../pages/conjuntos/crearVigilante').then(m => m.default || m),
        meta: {
            breadcrumb: [
                {
                    title: 'El Olympo Condominio', name: 'conjuntos.vigilantes'
                },
                {
                    title: 'Nuevo vigilante'
                },
            ]
        }
    }
]
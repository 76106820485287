import Configurar from './configurar/configurar'
import conjuntos from './conjuntos/conjuntos'
import Tiendas from "./tiendas/tiendas"
import Estadisticas from "./estadisticas/estadisticas"
import Ventanas from "./ventanas/ventanas"

export default [
    {
        path: '/',
        name: 'home',
        component: ()=>import('../pages/Home.vue').then(m=>m.default || m)
    },
    {
        path: '/error/:id_error',
        name: 'error',
        component: ()=>import('../pages/Error.vue').then(m=>m.default || m)
    },
    {
        path: '/crear-conjunto',
        name: 'crear-conjunto',
        component: ()=>import('../pages/conjuntos/crearConjuntos').then(m=>m.default || m),
        meta: {
            breadcrumb: [
                {
                    title: 'Crear conjunto'
                },
            ]
        }
    },
    {
        path: '/login',
        name: 'login',
        component: ()=>import('../pages/auth/login.vue').then(m=>m.default || m)
    },
    {
        path: '/informacion-general/:id',
        name: 'conjuntos.informacion-general',
        component: ()=>import('../pages/informacionGeneral.vue').then(m=>m.default || m)
    },
    {
        path: '/modulos/:id',
        name: 'conjuntos.modulos',
        component: ()=>import('../pages/modulos').then(m=>m.default || m)
    },
    {
        path: '/tipo-viviendas/:id',
        name: 'conjuntos.tipo-viviendas',
        component: ()=>import('../pages/tipoViviendas.vue').then(m=>m.default || m)
    },
    ...conjuntos,
    ...Configurar,
    ...Tiendas,
    ...Estadisticas,
    ...Ventanas,
]

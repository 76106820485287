<template>
    <div class="main-layout">
        <navbar v-if='currentRouteName!="login"'/>
        <div class="d-flex">
            <menuLateral v-if="$route.name && $route.name.match('conjuntos') || $route.name && $route.name.match('configurar.manager')" class="left-menu" />
            <div 
            class="position-relative app-wrapper default" 
            :style="`width:calc(100vw - ${ $route.name && $route.name.match('conjuntos') || $route.name && $route.name.match('configurar.manager') ? 255 : 0}px);`"
            >
                <miga v-if="$route.name && $route.name.match('conjuntos') || $route.name && $route.name.match('configurar.manager') || $route.name && $route.name.match('crear') || $route.name && $route.name.match('editar')" />
                <div :class="`contenedor px-0 ${$route.name == 'estadisticas' ? 'pt-0' : 'pt-3'} `">
                    <child />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import '../assets/sass/app.scss'
import {mapGetters} from 'vuex'
export default {
  name: 'MainLayout',
	computed: {
		...mapGetters({
				user:'auth/user',
		}),
		currentRouteName() {
			return this.$route.name;
		}
	},
	watch:{
		currentRouteName(val) {
			if(val!='login' && !this.user){
				this.$store.dispatch('auth/logout')
				this.$router.push({name:'login'})
			}
		}
	}
}
</script>
<style lang="scss" scoped>
main{
    padding-left: 65px !important
}
.app-wrapper {
    font-family: 'Rubik';
    color: var(--text-general);
    height: calc(100vh - 56px);
    overflow-y: auto;
    width:calc(100vw - 100px);
    margin-left: auto;
    // z-index: 1;
    display: flex;
    flex-flow: column;
    align-items: stretch;
    justify-content: stretch;

    & > .full-height-wrapper {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
        flex: 1 1 auto;

        .conf-section {
            flex: 1 1 auto;
            margin: 1.5rem 1rem;
            padding: 1rem;
        }

    }
}
.contenedor{
    height: 100%;
    width: 100%;
    margin: auto 0;
}
.left-menu{
    width: 255px;
}
</style>

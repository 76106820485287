<template>
    <el-drawer :visible.sync="drawer">
        <template #title>
            <div class="d-flex flex-column">
                <p class="f-medium f-20 text-black">{{ titulo }}</p>
                <div class="divider"></div>
            </div>
        </template>
        <slot />
    </el-drawer>
</template>

<script>
export default {
    props: {
        titulo: {
            type: String,
            default: ''
        }
    },
    data(){
        return {
            drawer: false,
        };
    },
    methods:{
        toggle(){
            this.drawer = !this.drawer
        }
    }
}
</script>

<style scoped>
.divider{
    width: 314px;
    height: 2px;
    transform: matrix(-1, 0, 0, -1, 0, 0);
    background: transparent linear-gradient(270deg, #E3E3E3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;
}
</style>

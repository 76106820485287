<template>
    <section 
    class="row mx-0 pt-4 mb-3 px-5"
    >
        <el-breadcrumb separator-class="el-icon-arrow-right" class="manager-breadcrum">
            <el-breadcrumb-item 
            class="text-general cr-pointer"
            @click.native="($route.name != 'home') ? $router.push({ name: 'home' }): null"
            >
                Conjuntos
            </el-breadcrumb-item>
            <el-breadcrumb-item
            v-if="conjuntoActivo" 
            class="text-general cr-pointer"
            @click.native="$router.back()"
            >
                {{conjuntoActivo}}
            </el-breadcrumb-item>

            <el-breadcrumb-item 
            v-for="(route, index) in breadcrumbList" 
            :key="index" 
            class="text-general cr-pointer"
            :class="{ '' : $route.name != route.name}"
            @click.native="($route.name != route.name) ? $router.push({ name: route.name }): null"
            >
                {{route.title}}
            </el-breadcrumb-item>
        </el-breadcrumb>
    </section>
</template>

<script>
export default {
    data(){
        return {
            excludedRoutes: ['ventanas.nueva']
        }
    },
    mounted(){
        this.$store.dispatch('conjuntos/quitarConjunto')
    },
    computed: {
        breadcrumbList(){
            let rutas = this.$route.meta.breadcrumb
            return rutas 
        },
        conjuntoActivo(){
            const conjunto = this.$store.getters['conjuntos/conjunto']
            return conjunto?.nombre ?? null
        }
  }
}
</script>
<style lang="scss" scoped>
.manager-breadcrum{
    color: var(--text-general) !important;
}
.cr-pointer{
    cursor: pointer;
}
.no-active{
    background-color: red
}
</style>

import Vue from 'vue'
import Global from '~/mixins/general'
import lodash from 'lodash'
import './vee-validate'
import './vue-quill'
import './draggable'
import './slim'
import moment from 'moment'
moment.updateLocale('es', {
    week: { dow: 0 }
})
Vue.prototype._ = lodash
Vue.mixin(Global)


<template>
	<el-select 
	  v-model="categoria_id" 
	  placeholder="Seleccionar" 
	  filterable    
	  remote
	  class="w-100 mb-3" size="small"
	>
	  <el-option
	 	v-for="categoria in categorias"
		:key="`categoria-${categoria.id}`"
		:label="categoria.nombre"
		:value="categoria.id"
	  />
  </el-select>
  </template>
  
  <script>
  import Util from '~/services/util'
  export default {   
	data(){
	  return{
		categorias:[],      
		categoria_id:null
	  }
	},
	mounted(){
	  this.getTiendasCategorias()
	},
	watch:{
	  categoria_id(val){
		const categoria = this.categorias.find(el => el.id == val);
		this.$emit('change', categoria)
	  }
	},
	methods:{    
	  async getTiendasCategorias(){  
		try {
		  const {data} = await Util.leecherosCategorias()
		  this.categorias = data.data
		} catch (e){
		  console.error(e)
		}
	  },
	  setId(categoria_id){
		this.categoria_id = categoria_id
	  }
	}
  }
  </script>
  
  <style>
  
  </style>
<template>
  <el-select v-model="id_ciudad" placeholder="Seleccionar" 
    filterable    
    remote
    :remote-method="remoteMethod"
    class="w-100 mb-3" size="small">
    <el-option
    v-for="ciudad in ciudades"
    :key="ciudad.id"
    :label="ciudad.nombre"
    :value="ciudad.id">
  </el-option>
</el-select>
</template>

<script>
import Util from '~/services/util'
export default {   
  data(){
    return{
      ciudades:[],
      params : {
        search:'',
        id_pais:null,
      },
      id_ciudad:null,
    }
  },
  // created(){
  //   this.search()
  // },
  watch:{
    id_ciudad(val){
      this.$emit('setCiudad',val)
    }
  },
  methods:{    
    remoteMethod(search){
      this.params.search = search
      setTimeout(() => {
        this.loading = false;
        this.search()
      }, 100);
    },
    async search(){
      try {
        const {data} = await Util.ciudadesFiltro(this.params)
        this.ciudades = data.data	
      } catch (e){
        console.log(e)
      }
    },
    setIdPais(id_pais){
      this.params.id_pais = id_pais
      this.search()
    } 
  }
}
</script>

<style>

</style>
export default [
	{
		path: '/ventanas-emergentes',
		name: 'ventanas',
		component: () => import('../../pages/ventanas').then(m => m.default || m),
		redirect: { name: 'ventanas.listado' },
		children: [
			{
				path: '',
				name: 'ventanas.listado',
				component: () => import('../../pages/ventanas/listado').then(m => m.default || m),
			},
			{
				path: 'nueva',
				name: 'ventanas.crear',
				meta: { name: 'Nueva', breadcrumb: [{ title: 'Ventana nueva', name: 'ventanas.crear'}] },
				component: () => import('../../pages/ventanas/crearVentana').then(m => m.default || m),
			},
			{
				path: 'editar/:id',
				name: 'ventanas.editar',
				meta: { name: 'Nueva', breadcrumb: [{ title: 'Ventana nueva', name: 'ventanas.editar'}] },
				component: () => import('../../pages/ventanas/editarVentana').then(m => m.default || m),
			}
		]
	},
]
import axios from 'axios'

const API = '/manager/util'

const Util = {
    paisesFiltro(params){
      return axios.get(`${API}/paises/filtro`, { params });
    },
    ciudadesFiltro(params){
      return axios.get(`${API}/ciudades/filtro`, { params });
    },
    leecherosCategorias(){
      return axios.get(`${API}/tiendas-categorias/filtro`);
    },
    leecherosFiltro(params){
      return axios.get(`/manager/leecheros/remotos`, { params });
    },  

}

export default Util

<template>
    <div
    ref="modalgbp"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    >
        <div
        class="modal-dialog modal-dialog-centered"
        :class="tamano ? tamano : ''"
        role="document"
        >
            <div class="modal-content">
                <div class="modal-header border-bottom mb-1">
                    <div class="col f-20 f-600 text-black">
                        {{ titulo }}
                    </div>
                    <div class="ml-auto my-auto d-middle-center bg-white rounded-circle" style="width:20px;height:20px;">
                        <i class="icon-cerrar f-14 cr-pointer text-black" data-dismiss="modal" />
                    </div>
                </div>
                <div class="modal-body overflow-auto custom-scroll py-0 my-3" style="max-height:70vh;">
                    <slot />
                    <div class="row mx-0 bg-linea" />
                </div>
                <div class="row mx-0 justify-content-end pb-2">
                    <div
                    v-if="!noCancelar"
                    class="bg-f5 border d-middle-center cr-pointer mx-2 br-4"
                    style="height:32px;width:94px;"
                    @click="cancelar"
                    v-text="textosBotones.cancelar"
                    />
                    <div
                    v-if="!noAceptar"
                    v-loading="cargando"
                    class="mx-2 bg-general d-middle-center text-white br-4"
                    style="height:32px;width:94px;"
                    @click="actionEliminar"
                    v-text="textosBotones.aceptar"
                    />
                    <div
                    v-show="eliminar"
                    v-loading="cargando"
                    class="mx-2 bg-danger d-middle-center text-white br-4"
                    style="height:32px;width:94px;"
                    @click="actionEliminar"
                    v-text="textosBotones.eliminar"
                    />
                    <button
                    v-show="listo"
                    v-loading="cargando"
                    type="button"
                    class="btn mx-2 btn-general"
                    @click="toggle"
                    v-text="textosBotones.listo"
                    />
                    <div
                    v-show="adicional !== ''"
                    v-loading="cargando"
                    :disabled="desactivarGuardar"
                    style="height:32px;width:94px;"
                    class="bg-general cr-pointer text-white border d-middle-center mx-2 br-4"
                    @click="onAdicional"
                    v-text="adicional"
                    />
                    <button
                    v-for="btn in btns"
                    v-show="btns.length"
                    :key="btn.text"
                    type="button"
                    class="btn mx-2"
                    :class="btn.className"
                    @click="btn.action"
                    v-text="btn.text"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        titulo: {
            type: String,
            required: true
        },
        tamano: {
            type: String,
            default: 'modal-md'
        },
        noAceptar: {
            type: Boolean,
            default: false
        },
        noCancelar: {
            type: Boolean,
            default: false
        },
        listo: {
            type: Boolean,
            default: false
        },
        eliminar: {
            type: Boolean,
            default: false
        },
        desactivarGuardar: {
            type: Boolean,
            default: false
        },
        adicional: {
            type: String,
            default: ''
        },
        btns: {
            type: Array,
            default: () => { return [] }
        },
        cargando: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: ''
        }
    },
    data(){
        return {
            textosBotones: {
                listo: 'Listo',
                aceptar: 'Aceptar',
                guardar: 'Guardar',
                eliminar: 'Eliminar',
                cancelar: 'Cancelar'
            }
        }
    },
    methods: {
        toggle(){
            $(this.$refs.modalgbp).modal('toggle')
        },
        guardar(){
            this.$emit('guardar')
        },
        actionEliminar(){
            this.$emit('eliminar')
        },
        onAdicional(){
            this.$emit('adicional')
        },
        cancelar(){
            this.$emit('cancelar')
            this.toggle()
        }
    }
}
</script>

<style lang="scss" scoped>

.bg-linea{
    background: linear-gradient(to right, #fff, #D1D1D1, #fff);
    height: 1px;
}

.modal-content{
    border: none;
    border-radius: 8px;

    .modal-header{
        // background:linear-gradient( rgba(50,163,253,.65) 1% , rgba(255,255,255,1) , rgba(255,255,255,1), rgba(255,255,255,1) );
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom: none;
        background-size: cover;

        .close{
            outline: none;
            transition-duration: .85s;
            position: absolute;
            right: 10px;
            top: 1px;
            font-size: 2.5em;

            &:hover{
                transition-duration: .4s;
                transform: rotate(360deg) scale(1.2);
            }
        }
        .logo{
            display: block;
            // height: 70px;
            // width: 70px;

            span{
                font-size: 1.2em;
            }
        }
    }
    .modal-title{
        color: #32a3fd;
        font-weight: normal;
    }
}
.img-tipo{
    position: absolute;
    top:20px;
    left: 50%;
    transform: translate(-50%);
}
</style>

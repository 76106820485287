<template>
    <div class="border-right" :style="`${$route.name && $route.name.match('conjuntos') || $route.name.match('configurar.manager') ? 'width:258px;' : 'width:100px;'}`">
        <div class="menu-lateral">
            <div v-if="showNavbar" class="overflow-auto scroll-none menu pt-2" style="height:calc(100% - 0px);">
                <div class="row mx-0 px-2 py-2" >
                    <div class="row w-100 j-center mx-2">
                        <img :src="conjunto.logo" width="80" height="80" class=" obj-cover rounded-circle border mb-2" />
                    </div>
                    <div class="col-12">
                        <p class="text-general j-center text-center f-15 f-600">{{conjunto.nombre}}</p>
                        <p class="text-general j-center text-center f-13">
                            <span v-if="conjunto.tipo==1">Apartamentos</span>
                            <span v-if="conjunto.tipo==2">Casas</span>
                            <span v-if="conjunto.tipo==3">Apartamentos y Casas</span>
                            <span v-if="conjunto.tipo==4">Otros</span>
                        </p>
                    </div>
                </div>
                <hr class="border mx-3 my-2" />
                <div v-for="(item, idx) in menus" :key="idx" class="row mx-0 px-2 my-3 j-center">
                    <router-link
                    v-slot="{ navigate, isActive }"
                    :data-xd="item.ruta"
                    :to="{ name: `${item.ruta}`, params: item.params || {} }"
                    :class="{'router-link-active': subIsActive(item.active)}"
                    class="pl-2"
                    >
                        <a
                        class="d-middle-center position-relative cr-pointer"
                        :class="{'router-link-exact-active router-link-active': isActive}"
                        @click="navigate"
                        >
                            <i class="f-17" :class="item.icon" />
                            <p class="col pl-2 pr-2">{{ item.titulo }}</p>
                        </a>
                    </router-link>
                </div>
                <!-- Configuracion -->
                <hr class="border mx-3" />
                <div class="row mx-0 align-items-center px-2 my-3 j-center pl-3">
                    <i class="f-20 text-black icon-configuracion-linea" />
                    <p class="col pl-2 pr-2 f-15 f-600">Configuracion</p>
                </div>
                <div class="row mx-0 px-2 my-3 j-center">
                    <router-link
                    v-slot="{ navigate, isActive }"
                    :to="{ name: `conjuntos.informacion-general` }"
                    :class="{'router-link-active': subIsActive(false)}"
                    >
                        <a
                        class="d-middle-center position-relative cr-pointer"
                        style="height:37px;"
                        :class="{'router-link-exact-active router-link-active': isActive}"
                        @click="navigate"
                        >
                            <div style="width:35px;" />
                            <p class="col pl-0 pr-2">Información general</p>
                        </a>
                    </router-link>
                </div>
                <div class="row mx-0 px-2 my-3 j-center">
                    <router-link
                    v-slot="{ navigate, isActive }"
                    :to="{ name: `conjuntos.tipo-viviendas` }"
                    :class="{'router-link-active': subIsActive(false)}"
                    >
                        <a
                        class="d-middle-center position-relative cr-pointer"
                        style="height:37px;"
                        :class="{'router-link-exact-active router-link-active': isActive}"
                        @click="navigate"
                        >
                            <div style="width:35px;" />
                            <p class="col pl-0 pr-2">Tipo de viviendas</p>
                        </a>
                    </router-link>
                </div>
                <div class="row mx-0 px-2 my-3 j-center">
                    <router-link
                    v-slot="{ navigate, isActive }"
                    :to="{ name: `conjuntos.modulos` }"
                    :class="{'router-link-active': subIsActive(false)}"
                    >
                        <a
                        class="d-middle-center position-relative cr-pointer"
                        style="height:37px;"
                        :class="{'router-link-exact-active router-link-active': isActive}"
                        @click="navigate"
                        >
                            <div style="width:35px;" />
                            <p class="col pl-0 pr-2">Modulos</p>
                        </a>
                    </router-link>
                </div>
            </div>
            <div v-if="showNavbarConf" class="overflow-auto scroll-none menu pt-4" style="height:calc(100% - 0px);">
                <div class="row mx-0 align-items-center px-2 py-2 cr-pointer" @click="$router.push({name: 'home'})">
                    <p class="col text-general f-600 f-16 text-center">
                        Configuración del sistema
                    </p>
                </div>
                <hr class="border mx-3" />
                <div v-for="(item, idx) in menuConf" :key="idx" class="row mx-0 px-2 my-3 j-center">
                    <router-link
                    v-slot="{ navigate, isActive }"
                    :data-xd="item.ruta"
                    :to="{ name: `${item.ruta}`, params: item.params || {} }"
                    :class="{'router-link-active': subIsActive(item.active)}"
                    class="pl-2"
                    >
                        <a
                        class="d-middle-center position-relative cr-pointer"
                        :class="{'router-link-exact-active router-link-active': isActive}"
                        @click="navigate"
                        >
                            <i class="f-18" :class="item.icon" />
                            <p class="menu-item col pl-2 pr-2">{{ item.titulo }}</p>
                        </a>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    data(){
			return {
				rutaActiva: '',
				menus: [
                    { titulo: 'Estadísticas del conjunto', ruta: 'conjuntos.estadisticas', icon: 'icon-estadisticas', cant: 0 },
                    { titulo: 'Estructura residencial', ruta: 'conjuntos.estructura-residencial', icon: 'icon-inmobiliaria', cant: 0 },
                    { titulo: 'Administradores', ruta: 'conjuntos.administradores', icon: 'icon-solicitud', cant: 0 },
                    { titulo: 'Vigilantes', ruta: 'conjuntos.vigilantes', icon: 'icon-usuario_escudo', params: {id_vigilante: 1}, cant: 0 },
                    { titulo: 'Leecheros asignados', ruta: 'conjuntos.leecheros-asignados', icon: 'icon-leechero', cant: 0 },
				],
				menuConf: [
                    { titulo: 'Tipos de anuncios', ruta: 'configurar.manager.tipo-anuncios', icon: 'icon-anuncios', cant: 0 },
                    { titulo: 'Tipo de correspondencia', ruta: 'configurar.manager.tipo-correspondencia', icon: 'icon-correo', cant: 0 },
                    { titulo: 'Categorías de tienda', ruta: 'configurar.manager.tiendas-categorias', icon: 'icon-leechero', cant: 0 },
                    { titulo: 'Lineas de atención', ruta: 'configurar.manager.lineas-atencion', icon: 'icon-telefono', cant: 0 },
                    { titulo: 'Preguntas frecuentes', ruta: 'configurar.manager.preguntas-frecuentes', icon: 'icon-interrogacion', cant: 0 },
                    { titulo: 'Términos y condiciones', ruta: 'configurar.manager.terminos-condiciones', icon: 'icon-noticias', cant: 0 },
                    { titulo: 'Políticas de privacidad', ruta: 'configurar.manager.politicas-privacidad', icon: 'icon-candado f-20', cant: 0 },
                    { titulo: 'Versiones de las apps', ruta: 'configurar.manager.versiones', icon: 'icon-candado f-20', cant: 0 },
				]
			}
    },
		mounted(){
      this.obtenerConjunto()
    },
    computed: {
        ...mapGetters({
					conjunto: 'conjuntos/conjunto',
        }),
        isActive(){
            return this.$route.name
        },
        showNavbar(){
            return this.$route.params.id && this.$route.name.match('conjuntos')
        },
        showNavbarConf(){
            return this.$route.name && this.$route.name.match('configurar.manager')
        },
    },
    methods: {
        menuSeleccionado(){
            return this.menus[0]
        },
        ir(ruta){
            this.rutaActiva=ruta
            this.$router.push({ name: ruta })
        },
        subIsActive(input){
            const paths = Array.isArray(input) ? input : [input]
            return paths.some(path => {
                return this.$route.path.indexOf(path) === 0 || this.$route.path.indexOf(path) === 1 || this.$route.name === path
            })
        },
				async obtenerConjunto(){

                    try {
					    await this.$store.dispatch('conjuntos/obtener',this.$route.params.id)
                    } catch (error) {
                        this.error_catch(error)
                        this.$router.replace({name: "error",params:{id_error:1}})
                    }
					
				}
    }
}
</script>

<style lang="scss" scoped>
.cont-menu{
    position: fixed;
    top:55px;
    height: calc(100vh - 57px);
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.menu-lateral{
    width:100%;
    height: 100%;
    background: #FAFBFC;
}
.badge{
    position: absolute;
    top: 2px;
    left: 35px;
    border-radius: 20px!important;
    border: 1px solid #fff;
    color: #fff;
    background: linear-gradient(to right, #FF9D32 5%, #FF2525)
}
.menu{
    a{
        width:100%;
        height: 38px !important;
        color: var(--text-general);
        i{
            color: #000000;
        }
        &:hover{
            background-color: #EBECF0 !important;
        }
        
        
        &.router-link-exact-active.router-link-active{
            background-color: #EBECF0 !important;
            border-radius: 4px;
            color: var(--color-general) !important;
            i{
                color: var(--color-general) !important;
            }
        }
        &:hover{
            text-decoration: none !important;
        }
    }
}
</style>

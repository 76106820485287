import axios from 'axios'

const API = '/manager/conjuntos'

const Conjuntos = {
    listarConjuntos(){
      return axios(`${API}`)
    },
    filtro(params){
      return axios.get(`${API}/filtro`,{params})
    },
    guardar(payload){
      return axios.post(`${API}/guardar`,payload)
    },
    getConjunto(params){
      return axios.get(`${API}/obtener-conjunto`,{params})
    },
    inactivarConjunto(idConjunto){
      return axios.put(`${API}/${idConjunto}/inactivar`)
    },
    activarConjunto(idConjunto){
      return axios.put(`${API}/${idConjunto}/activar`)
    },
    eliminarConjunto(idConjunto){
      return axios.delete(`${API}/${idConjunto}/eliminar`)
    },
    getCiudad(params){
      return axios.get(`${API}/ciudades`,{params})
    }
}

export default Conjuntos

export default [
    {
        path: '/configurar',
        name: 'configurar.manager',
        component: () => import(/* webpackChunkName: "configurar" */ '../../pages/configurar').then(m => m.default || m),
        redirect: {name: 'configurar.manager.tipo-anuncios'},
        children: [
            {
                path: 'tipo-anuncios',
                name: 'configurar.manager.tipo-anuncios',
                component: () => import(/* webpackChunkName: "configurar" */ '../../pages/configurar/tipoAnuncios').then(m => m.default || m),
            },
            {
                path: 'tipo-correspondencia',
                name: 'configurar.manager.tipo-correspondencia',
                component: () => import(/* webpackChunkName: "configurar" */ '../../pages/configurar/tipoCorrespondencia').then(m => m.default || m),
            },
            {
                path: 'tiendas-categorias',
                name: 'configurar.manager.tiendas-categorias',
                component: () => import(/* webpackChunkName: "configurar" */ '../../pages/configurar/tiendasCategorias').then(m => m.default || m),
            },
            {
                path: 'lineas-atencion',
                name: 'configurar.manager.lineas-atencion',
                component: () => import(/* webpackChunkName: "configurar" */ '../../pages/configurar/lineasAtencion').then(m => m.default || m),
            }
        ]
    },
    {
        path: '/configurar/tipo-anuncios/nuevo',
        name: 'configurar.manager.crear-tipo-anuncio',
        component: () => import(/* webpackChunkName: "configurar" */ '../../pages/configurar/crearAnuncio').then(m => m.default || m),
        meta: {
            breadcrumb: [
                {
                    title: 'Nuevo tipo de anuncio'
                },
            ]
        }
    },
    {
      path: '/configurar/tipo-anuncios/:id',
      name: 'configurar.manager.editar-tipo-anuncio',
      component: () => import(/* webpackChunkName: "configurar" */ '../../pages/configurar/crearAnuncio').then(m => m.default || m),
      meta: {
        breadcrumb: [
          {
            title: 'Editar tipo de anuncio'
          },
        ]
      }
    },
    {
        path: '/configurar/tipo-correspondencia/nuevo',
        name: 'configurar.manager.crear-correspondencia',
        component: () => import(/* webpackChunkName: "configurar" */ '../../pages/configurar/crearCorrespondencia').then(m => m.default || m),
        meta: {
            breadcrumb: [
                {
                    title: 'Nuevo tipo de correspondencia'
                },
            ]
        }
    },
    {
        path: '/configurar/preguntas-frecuentes',
        name: 'configurar.manager.preguntas-frecuentes',
        component: () => import(/* webpackChunkName: "configurar" */ '../../pages/configurar/preguntasFrecuentes').then(m => m.default || m),
    },
    {
        path: '/configurar/terminos-condiciones',
        name: 'configurar.manager.terminos-condiciones',
        component: () => import(/* webpackChunkName: "configurar" */ '../../pages/configurar/terminosCondiciones').then(m => m.default || m),
    },
    {
        path: '/configurar/politicas-privacidad',
        name: 'configurar.manager.politicas-privacidad',
        component: () => import(/* webpackChunkName: "configurar" */ '../../pages/configurar/politicasPrivacidad').then(m => m.default || m),
    },
    {
        path: '/configurar/versiones',
        name: 'configurar.manager.versiones',
        component: () => import(/* webpackChunkName: "configurar" */ '../../pages/configurar/versiones').then(m => m.default || m),
    }
]

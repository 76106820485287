import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './components'
import './library'
import './plugins'
Vue.config.productionTip = false

new Vue({
    el: '#app',
    store,
    router,
    ...App
})

export default [
    {
        path: '/tiendas',
        name: 'tiendas.main',
        component: () => import('../../pages/tiendas/indexMenu').then(m => m.default || m),
        children:[
            {
                path: ':idCategoria',
                name: 'tiendas-proveedores',
                component: () => import('../../pages/tiendas').then(m => m.default || m),
            },
        ]
    },
    {
        path: '/servicios',
        name: 'tiendas-proveedores2',
        component: () => import(/* webpackChunkName: "configurar" */ '../../pages/tiendas').then(m => m.default || m),
    },
    {
        path: '/servicios/:idServicio/detalle',
        name: 'tiendas-proveedores.crear',
        component: () => import(/* webpackChunkName: "configurar" */ '../../pages/tiendas/crear').then(m => m.default || m),
    },
    {
        path: '/servicio/crear/categoria/:idCategoria',
        name: 'tiendas-proveedores.crear.parte-uno',
        component: () => import(/* webpackChunkName: "configurar" */ '../../pages/tiendas/crearPrimeraFase').then(m => m.default || m),
    },
]